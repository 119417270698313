// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assinatura-recebida-tsx": () => import("./../../../src/pages/assinatura-recebida.tsx" /* webpackChunkName: "component---src-pages-assinatura-recebida-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-planos-tsx": () => import("./../../../src/pages/planos.tsx" /* webpackChunkName: "component---src-pages-planos-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */)
}

